body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form__field-error {
  color: red !important;
}

.md-nav-sidebar {
  z-index: 99;
}

.profile-upgrade form {
  border-radius: 0px !important;
  padding: 36px 48px 22px 48px !important;
}

.entities-container {
  /* box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04); */
  background: #ffffff;
  padding: 36px 48px 65px 48px;
}

.entities-container .btn-medium {
  width: 100% !important;
}

.entities-container .button-group {
  width: 100% !important;
  display: flex !important;
}

.uploadIcon {
  width: 48px !important;
  height: 40px !important;
}

.ui .page .modals .dimmer .transition .visible .active {
  max-width: 100%;
  margin: 70px 301px !important;
  height: 199px;
}

.ui .small .modal .transition .visible .active {
  margin: 70px 301px !important;
  height: 199px;
}

.active .modal {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 56% !important;
  height: auto;
  overflow: hidden;
  outline: 0;
  margin: 100px 22% !important;
}

.ui .small .modal .transition .visible .active .actions button {
  min-height: 40px;
  border-radius: 2px;
  background-color: #006aff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #ffffff;
  padding: 8px 20px 7px 20px;
  line-height: 1.71;
  border: none;
  height: auto;
  white-space: normal;
}

.previewUrl {
  max-width: 100% !important;
  height: 90px !important;
}

.dzu-previewImage {
  height: 100px;
  width: 100px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.rdt_Table {
  width: 100%;
  max-width: 100%;
  height: auto !important;
  margin-bottom: 1rem;
  background-color: black !important;
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: grey;
}

.rdt_TableCell {
  font-weight: 500 !important;
  line-height: 1.4 !important;
  color: #002233;
  text-align: right;
  font-size: 16px !important;
  border-bottom: 1px solid #ebeeef;
  border-top: none;
  vertical-align: middle;
  padding-top: 21px;
  padding-bottom: 17px;
  position: relative;
  padding: 8px !important;
}

.rdt_TableCol {
  font-size: 14px !important;
  line-height: 1.43 !important;
  color: #6e8b9a !important;
  text-align: left;
  border-bottom: 1px solid #ebeeef;
  border-top: none;
  cursor: pointer;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  vertical-align: top;
}

.rdt_TableHeadRow,
.rdt_TableRow {
  border-bottom: none !important;
}
.rdt_TableHeadRow:hover,
.rdt_TableRow:hover {
  background: #ecf3f7 !important;
}

.rdt_ExpanderRow {
  background: #ecf3f7 !important;
}

.rdt_ExpanderRow .timeline .item {
  margin: 0px 0px 17px 0px;
  display: table;
}

.md-table-normal .timeline {
  background: url(./assets/images/dashed-timeline.png) repeat-y left 130px top
    2px;
  margin: 7px 5px 33px;
  padding: 0px 0px 10px;
}

.md-table-normal .timeline .item .date-time {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 0px 0px 0px;
}

.md-table-normal .timeline .item .action {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 0px 0px 73px;
  position: relative;
}

.md-table-normal .timeline .item .date-time span.date {
  font-size: 16px;
  color: #6e8b9a;
  font-size: 14px;
  line-height: 1.38;
  display: block;
  font-weight: 300;
  font-size: 14px;
  color: #6e8b9a;
}

.md-table-normal .timeline .item .action {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 0px 0px 73px;
  position: relative;
}

.md-table-normal .timeline .item .action:before {
  content: "";
  width: 7px;
  height: 7px;
  display: block;
  background: #006aff;
  border-radius: 50%;
  position: absolute;
  left: 31px;
  top: 17px;
}

/* radio buttons css */
.radio-inline li.rw-list-option {
  padding: 5px;
  display: inline;
}
.radio-inline .rw-select-list-input {
  margin-right: 7px;
}
.radio-inline .rw-select-list-label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  color: #6e8b9a;
}

html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
}

/* Validation Error  */
.form__field-error {
  color: red !important;
  font-size: 12px;
}

div .ui .selection .dropdown {
  width: 100%;
}

.p-100 {
  padding: 100px;
}
.anticon.custom {
  vertical-align: unset !important;
}

.alert-danger.outline {
  background-color: #ffffff !important;
  border: 2px solid #f53c44 !important;
  color: #f53c44 !important;
  font-weight: 700;
  padding: 0.25rem 1em;
}

.alert-warning.outline {
  background-color: #ffffff !important;
  border: 2px solid #fac878 !important;
  color: #fac878 !important;
  padding: 0.25rem 1em;
  font-weight: 700;
}

.stock-form {
  min-height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stock-form-no-limit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter .ant-modal-close {
  position: absolute;
  top: -11px;
  right: -10px;
}

.newsletter-spinner {
  position: absolute;
  display: flex;
  width: 820px !important;
  height: 315px;
  justify-content: center;
  align-items: center;
  background: white;
}

.pic-container {
  border: 1px solid lightgrey;
  height: 75px !important;
  /* background-color: lightgrey; */
  width: 75px;
  border-radius: 50%;
  object-fit: cover !important;
}

.rdt_Pagination select{
  min-width: 35px!important;
}

.text-about.policy ul{
  padding-left: 20px;
}

.ant-modal-close{
  top: -12px;
  right: -9px;
}

.page-signup .form-control{
  background-color: #fff!important;
}

.page-signup .ant-select-selection{
  height: 43px;
}

.page-signup .ant-select-selection__rendered{
  padding: 5px;
}

.verify-phone-input input:first-child {
    border-top-left-radius: 0px!important; 
    border-bottom-left-radius: 0px!important;
}

.verify-phone-input input:first-child {
    border-top-left-radius: 0px!important; 
    border-bottom-left-radius: 0px!important;
}

.verify-phone-input input:last-child {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}

.verify-phone-input input{
  margin-left: 11px!important;
  border-right: solid 1px #a8adb7!important;
}

.verify-phone-input{
  margin: auto;
  width: 426px!important;
  /* width: 100%!important; */
}

.verify-phone-input input:focus + input {
  border-left: 1px solid #006fff!important;
}

.verify-phone-input input:nth-child(4):before {
  display : block;
  text-align : center;
  font-size : 4em;
  margin : 20px auto;
  color: #fff;
  content: "—";
}


.message-page{
    border-radius: 8px;
    box-shadow: 0 52px 68px 0 rgb(0 0 0 / 4%);
    background: #ffffff;
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 500px;
    padding: 10px;
}

.btn-outline:hover{
  background: #2767FF!important;
  color: #fff!important;
}

.marquee-container .overlay::before, .overlay::after {
    background: none!important;
  }

  .deal-access-error{
    color: #f53c44 !important;
    font-weight: 700;
    padding: 0.25rem 1em;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    background-color: #FFFFFF;
    min-height: 120px;
    text-align: center;
    border-top: 1px solid #ebeeef;
    border-bottom: 1px solid #ebeeef;
    padding-top: 50px;
  }

  .branding-desc{
    padding-right: 53px!important;
    padding-top: 45px;
  }

  .hero_heading{
    font-size: 41px;
    font-weight: 600;
    color: #2767FF;
  }

 .hero_heading_2{
    font-weight: 300;
    color: #2767FF;
  }

a.nounderline:hover {
  text-decoration: none!important;
}

.live_btn{
  font-size: 12px;
  width: 108px;
  border-radius: 2px;
  height: 39px !important;
  text-align: center !important;
  padding-top: 9px !important;
  margin-top: -2px !important;
  margin-left: 10px !important;
}