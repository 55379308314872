$breakpoint-tablet-small: 576px;
$breakpoint-tablet-medium: 768px;
$breakpoint-tablet-large: 992px;
$breakpoint-tablet-extra-large: 1200px;
.new-plans{
    background-color: #037BFF07;
    background-image: url(img/plansbg.svg);
    min-height: 1064px !important;
    background-size: 122%;
    background-position: -209px 440px;
    background-repeat: no-repeat;
    position: relative;

    .card-body{
        padding:2.3rem;
    }
    .title{
        text-align: center;
        font-size:25px;
        letter-spacing: 0px;
        color: #037BFF!important;
        opacity: 1;
        padding-top:50px;

    }
    .sub-title{
        text-align: center;
        letter-spacing: 0px;
        color: #001833;
        font-size: 35px;
        font-weight:600;
    }

    .top-content{
        width:889px;
        margin-left:auto;
        margin-right:auto;
        padding:30px;

        @media screen and (max-width: $breakpoint-tablet-small) {
            width:100%;
        }
        @media screen and (max-width: $breakpoint-tablet-medium) {
            width:100%;
        }
    }

    .excert{
        text-align: center;
    }

    .top-left{
        position: absolute;
        min-height: 400px;
        top: -147px;
        left: -197px;
        z-index: 1;
        @media screen and (max-width: $breakpoint-tablet-small) {
            top: -250px;
            left: -226px;
        }
       
    }

    .section-content{
        width:889px;
        margin-left:auto;
        margin-right:auto;
        padding:30px;
       
        @media screen and (max-width: $breakpoint-tablet-small) {
            width:100%;
            .pro-plan{
                margin-top:50px;
            }
        }
        @media screen and (max-width: $breakpoint-tablet-medium) {
            width:100%;
            .pro-plan{
                margin-top:50px;
            }
        }
    }

    .title-image{
        height: 32px;
        margin-top:10px;
        margin-bottom:10px;
        img{
            height: 35px;
            width:35px;
        }
    }

    .title-text{
        text-align: center;
        font-weight: bold;
        font-size: 32px;
        letter-spacing: 0px;
        opacity: 1;
        color:#001833;
        margin-bottom:10px;
        &.font-primary{
            color: #037BFF!important;
        }
    }

    .hover-none:hover{
        text-decoration: none!important;
    }   

    .desc{
        font-size: 16px;
    }

    .checklists{
        text-align: left;
        letter-spacing: 0px;
        color: #001733;
        opacity: 1;
        text-align: left;
        letter-spacing: 0px;
        color: #001733;
        opacity: 1;
        font-size:13px;
        line-height: 2;
    }

    .r-icon{
        margin-top: -2px;
    }

    .starter-plan{
        min-height: 100%;
        padding-left: 30px !important;
        padding-right: 30px !important;
        z-index:999;
        position: relative;

    }

    .pro-plan{
        min-height: 100%;
        padding-left: 30px !important;
        padding-right: 30px !important;
        position: relative;
        z-index:999;
    }

    .button-bottom{
        position: absolute;
        bottom: 0;
        width: 90%;
        left: 2.3rem;
        padding-bottom: 32px;
    }

    .title-desc{
        padding: 70px;
    }

    .terms{
        text-align: left;
        font-style:italic;
        font-size:11px;
        letter-spacing: 0px;
        color: #93A1B2;
        opacity: 1;
        padding-left:30px;
        padding-right:30px;
    }

    .circle-bg{
        position: absolute;
        top: 34px;
        right: -249px;
        z-index: 9;
    }

    .relative{
        position: relative;
    }

    .card{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: none;
    }
}