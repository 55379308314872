$breakpoint-tablet-small: 576px;
$breakpoint-tablet-medium: 768px;
$breakpoint-tablet-large: 992px;
$breakpoint-tablet-extra-large: 1300px;
$breakpoint-tablet-extra-large: 1500px;

.new-signup{
    &.login{
        .r-row{
            min-height:800px!important;
        }
    }
    .bg-r-dark{
        background-color: #001833;
    }

    .vh-100{
        height:100vh;
    }

    .vw-60{
        margin: auto;
        max-width: 60%;

        @media screen and (max-width: $breakpoint-tablet-small) {
            width:100%;
        }
    }

    .r-title{
        text-align: left;
        font-size: 46px;
        letter-spacing: 0px;
        color: #347CF6;
        font-weight: bold;
        line-height: 1.3;
    }

    .stripes{
        background-color: #037BFF07;
        background-image: url(img/stripes.svg);
        height: 100% !important;
        background-size: 528px;
        background-position: -169px 349px;
        background-repeat: no-repeat;

        @media screen and (max-width: 1500px) {
            background-size: 528px!important;
            background-position: -199px 361px!important;
        }
        @media screen and (max-width: 1300px) {
            background-size: 528px!important;
            background-position: -220px 361px!important;
        }
        @media screen and (max-width: 1100px) {
            background-size: 528px!important;
            background-position: -250px 361px!important;
        }

    }

    .form-account-create{
        margin-top:146px;
    }

    .sub-heading{
        text-align: left;
        font: normal normal normal 25px/29px Montserrat;
        letter-spacing: 0px;
        color: #01165C;
        opacity: 1;
    }

    .checklists{
        text-align: left;
        letter-spacing: 0px;
        color: #001733;
        opacity: 1;
        text-align: left;
        font: normal normal normal 20px/38px Montserrat;
        letter-spacing: 0px;
        color: #001733;
        opacity: 1;
    }

    .r-icon{
        margin-top: -3px;
    }

    .r-row{
        min-height:1100px;
    }

    .bg-light-blue{
        background-color: #F8FBFF;
    }

    .form-bg{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        padding:30px;
    }

    .login{
        text-align: left;
        font-size: 25px;
        letter-spacing: 0px;
        color: #001833;
        opacity: 1;
    }

    .login-link{
        text-decoration: underline;
    }
}