.articles{

    .heading{
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
    }

    .d-block {
        display: block !important;
    }

    .post-entry.small-post-entry-v .thumbnail {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        margin-bottom: 20px;
        border-radius: 10px;
    }

    img {
        border-radius: 10px!important;
        // height: 250px;
        max-width: 100%;
        // object-fit: cover;
    }

    .post-entry.small-post-entry-v .heading {
        font-size: 18px;
    }

    .mb-3 {
        margin-bottom: 1rem !important;
    }

    .section {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .content{
        min-height: 115px;
    }

}